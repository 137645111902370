export default function MobileMenu (selector = '.menu') {
  const $handler = typeof selector === 'string' ? document.querySelector(selector) : selector
  if (!$handler) return

  let menuState = false
  const $menu = document.querySelector('.carcass_mask')
  const $html = document.querySelector('html')
  const $menuToggle = document.querySelector('.menu div')
  $handler.addEventListener('click', onHandlerClick)

  function onHandlerClick (e) {
    menuState ? reset() : enable()
  }

  function enable () {
    menuState = true
    toggleMenu('add')

    window.requestAnimationFrame(() => {
      window.addEventListener('click', onWindowClick)
      window.addEventListener('touchmove', onWindowClick)
    })
  }

  function reset () {
    toggleMenu('remove')
    menuState = false
    window.removeEventListener('click', onWindowClick)
    window.removeEventListener('touchmove', onWindowClick)
  }

  function toggleMenu (action) {
    $menu.classList[action]('carcassAni')
    $menuToggle.classList[action]('open')
    $html.classList[action]('menu_opened')
  }

  function onWindowClick (e) {
    const isInsideMenu = e.target.closest('.left_menu')
    if (!isInsideMenu) {
      reset()
    }
  }
}
