import AppPromo from './component/AppPromo'
import MobileMenu from './component/MobileMenu'
import FieldCleaner from './component/FieldCleaner'
import SimpleCallbackForm from './component/SimpleCallbackForm'
import AuctionValidation from './component/AuctionValidation'

window.onload = function () {
  AppPromo('.app-promo_js-android-app', {
    strategy: 'perWeek',
    showForCertainOS: 'android',
    identifyer: '#123wqdsaxc',
    onEnable () {
      const $carcass = document.querySelector('.carcass_mask')
      $carcass.classList.add('carcass_mask_app-promo')
    },
    onDisable () {
      const $carcass = document.querySelector('.carcass_mask')
      $carcass.classList.remove('carcass_mask_app-promo')
    }
  })

  /* main search */
  FieldCleaner()

  MobileMenu()

  SimpleCallbackForm({
    selector: '.callback_t',
    classes: {
      btn: 'button',
      currentInput: 'input',
      inputForm: 'input_num',
      correctForm: 'correct',
      input: {
        error: 'error-input',
        hide: 'hide_form',
        show: 'show_correct'
      }
    }
  })

  AuctionValidation({
    selector: '.without_active',
    classes: {
      btn: 'button',
      accept: 'auction_validation_accept',
      error: 'auction_validation_error',
      input: 'auction_form'
    }
  })

  /* gift-delivery */
  if (document.querySelector('.gift-delivery__close')) {
    document.querySelector('.gift-delivery__close').addEventListener('click', function () {
      document.querySelector('.gift-delivery').classList.remove('gift-delivery_active')
    })
  }
}
