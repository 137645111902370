/*
  selector - селектор основного блока-обертки

  Объект с параметрами:
  {
    strategy - string стратегия показа баннера. Доступные варианты: showOnce, perWeer
    identifyer - string уникальный идентификатор, чтобы можно было различать разные блоки из хранилища
    classes - object объект с классами для основных элементов, пример структуры ниже
    showForCertainOS - array/string платформа для которой показываем блок, берется из строки userAgent
    onEnable - function каллбэк при показе блока
    onDisable - function каллбэк при скрытии блока
  }
*/

import merge from 'lodash.merge'
import ShareToGlobal from './ShareToGlobal'

export default function AppPromo (
  selector,
  {
    strategy = false,
    identifyer = false,
    classes = {},
    showForCertainOS = false,
    onEnable = () => null,
    onDisable = () => null
  } = {}
) {
  const promo = document.querySelector(selector)
  if (!promo) return

  if (!identifyer) {
    throw Error(
      'Для промо баннера нужно передать уникальный идентификатор в параметрах'
    )
  }

  const cls = getClasses(classes)
  const close = promo.querySelector(`.${cls.close.base}`)
  const userAgent = navigator.userAgent.toLowerCase()
  if (isProperOS(showForCertainOS)) {
    if (wasShown()) {
      if (strategy === 'perWeek') {
        if (daysPassed() > 7) {
          enable()
        }
      } else if (strategy === 'showOnce') {
        disable()
      } else {
        enable()
      }
    } else {
      enable()
    }
  }

  function isProperOS (os) {
    if (os) {
      if (os instanceof Array) {
        return os.some(el =>
          userAgent.indexOf(el.toLowerCase()) !== -1
        )
      } else if (typeof os === 'string') {
        return userAgent.indexOf(os.toLowerCase()) !== -1
      }
    } else {
      return true
    }
  }

  function wasShown () {
    return window.localStorage.getItem('app-promo-banner-' + identifyer)
  }

  function daysPassed () {
    let date = window.localStorage.getItem('app-promo-banner-' + identifyer)
    if (!date) return false

    date = new Date(date)
    const days = (new Date() - date) / 1000 / 60 / 60 / 24
    console.log('Прошло' + days + 'дней')
    return days
  }

  function enable () {
    promo.classList.add(cls.promo.active)
    close.addEventListener('click', disable)
    onEnable()
  }

  function disable () {
    updateTimestamp()
    promo.classList.remove(cls.promo.active)
    close.removeEventListener('click', disable)
    onDisable()
  }

  function updateTimestamp () {
    window.localStorage.setItem(
      'app-promo-banner-' + identifyer,
      new Date().toJSON()
    )
  }

  function getClasses (obj) {
    return merge(
      {
        promo: {
          base: 'app-promo',
          active: 'app-promo_active'
        },
        close: {
          base: 'app-promo__close'
        }
      },
      obj
    )
  }
}

ShareToGlobal.share('AppPromo', AppPromo)
